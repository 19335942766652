var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('subscriptions.subscriptions'),"description":_vm.$t('subscriptions.FromHereYouCanControlYoursubscriptions'),"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('subscriptions.SearchForASubscription'),"emptyTableText":_vm.$t('subscriptions.Nosubscriptions'),"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.admin || _vm.$user.role.subscriptions_add,"cloumns":[
          {column: 'name',    title:_vm.$t('subscriptions.SubscriptionName') ,type: 'mainLink' , sort: true },
          {column: 'day',  title:_vm.$t('subscriptions.SubscriptionPeriod')  ,type: 'text'     , sort: true, after: 'يوم', icon: 'stopwatch' },
          {column: 'price', title:_vm.$t('subscriptions.SubscriptionPrice')      ,type: 'text', sort: true, icon: 'usd-square'},
          {column: 'options', title:_vm.$t('subscriptions.Settings'), type: 'options', options: [
              {name: 'show'},
              {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.subscriptions_edit},
              {name: 'delete', role: _vm.$user.admin || _vm.$user.role.subscriptions_delete} ]}
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheProduct'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading'),
        }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }