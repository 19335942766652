<template>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('subscriptions.subscriptions')"
                     :description="$t('subscriptions.FromHereYouCanControlYoursubscriptions')"
                     :explanationPath="path + '/explaination'" />
        <indexTable :searchText="$t('subscriptions.SearchForASubscription')"
          :emptyTableText="$t('subscriptions.Nosubscriptions')"
          :localData="true"
          :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
          :buttonRole="$user.admin || $user.role.subscriptions_add"
          :cloumns="[
            {column: 'name',    title:$t('subscriptions.SubscriptionName') ,type: 'mainLink' , sort: true },
            {column: 'day',  title:$t('subscriptions.SubscriptionPeriod')  ,type: 'text'     , sort: true, after: 'يوم', icon: 'stopwatch' },
            {column: 'price', title:$t('subscriptions.SubscriptionPrice')      ,type: 'text', sort: true, icon: 'usd-square'},
            {column: 'options', title:$t('subscriptions.Settings'), type: 'options', options: [
                {name: 'show'},
                {name: 'edit',   role: $user.admin || $user.role.subscriptions_edit},
                {name: 'delete', role: $user.admin || $user.role.subscriptions_delete},
            ]}
          ]"
          :deleteText="{
            attention: $t('allerts.Attention'),
            areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheProduct'),
            close: $t('allerts.close'),
            confirm: $t('allerts.confirm'),
            loading: $t('allerts.loading'),
          }" 
        />
      </div>
    </div>
  </div>

</template>
<script>

  import indexHeader from '../elements/index/indexHeader.vue'
  import indexTable from '../elements/index/indexTable.vue'

  export default {
    data() {
      return {
        path: '/subscriptions'
      }
    },
    components: {
      indexHeader,
      indexTable
    }
  }

</script>
